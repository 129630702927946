
import { TextField } from '@material-ui/core';
import React from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { checkRequestErrors } from '../../const/api_erros';
import { notificationActions } from '../../modules/General/_redux/fileRedux';
import { getIntervalValue, postIntervalValue } from '../../modules/System/_redux/SystemApi';


export const Interval = () => {

    const [interval, setInterval] = React.useState(0);
    const [input, setInput] = React.useState("");
    const dispatch = useDispatch();
    const { authToken } = useSelector(({ auth }) => ({
        authToken: auth.authToken,
    }));

    React.useEffect(() => {
        try {
            getInterval();    
        } catch (error) {
            dispatch(notificationActions.setNotification("No es posible acceder al valor", "error"));
        }
    }, []);

    const getInterval = async () => {
        const response = await getIntervalValue( authToken )
        checkRequestErrors( dispatch, response );
        const data = await response.json();
        setInterval( data );
        setInput( data );
    }

    const updateValue = async () => {
        try {
            const response = await postIntervalValue( input, authToken );
            checkRequestErrors( dispatch, response );
            dispatch(notificationActions.setNotification("Actualizado correctamente", "success"))
            getInterval();
        } catch (error) {
            dispatch(notificationActions.setNotification("No se ha actualizado el intervalo", "error"));
        }
    }

    return (
        <div className="card card-custom card-stretch gutter-b">

            {/* Header */}
            <div className="card-header border-0 py-5">

                {/* Title */}
                <div className="card-title">
                    <div>
                        <h3 className="text-dark"> Intervalo de tiempo - lectura sistema </h3>
                    </div>
                </div>

            </div>

            <hr className="ml-10 mr-10" />

            {/* Body */}

            <div className="card-body">
                
                <div className="container">
                    <h4>El intervalo actual es: {interval} <small> milisegundos </small> </h4>
                </div>

                <div className="container mt-10">
                    <h4>Actualizar intervalo</h4>
                    <div className="d-flex align-items-center">
                        <TextField 
                            type="number"
                            color="secondary"
                            value={input}
                            variant="outlined"
                            onChange = {(event) => setInput(event.target.value)}
                            helperText="Tiempo en milisegundos"
                        />
                        <button
                            type="button"
                            className="btn btn-primary ml-5"
                            onClick = { updateValue }
                        >
                            Actualizar
                        </button>
                    </div>
                </div>

                
            </div>
        </div>
    )
}
