
import { createSlice } from '@reduxjs/toolkit'

const initStateFilters = {
  filter: {},
  data: [],
  dataCount: 0,
}

const filtersSlice = createSlice({
  name: "filters",
  initialState: initStateFilters,
  reducers: {
    setData: (state, action) => {
      const { data } = action.payload;
      state.data = data;
    },
    setDataCount: (state, action) => {
      const { dataCount } = action.payload;
      state.dataCount = dataCount;
    },
    setFilter: (state, action) => {
      const { field, data } = action.payload;
      if ( data !== "" ){
        if ( field === "estadoEmbargo" )
          state.filter.estadoEmbargo = [data];
        else
          state.filter[field] = data;
      }else{
        delete state.filter[field];
      }
    },
    resetFilter: (state) => {
      state.filter = {};
      state.data = [];
      state.dataCount = 0;
    }
  }
});

export default filtersSlice;