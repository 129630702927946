/* eslint-disable jsx-a11y/role-supports-aria-props */
/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React from "react";
import { useLocation } from "react-router";
import { NavLink } from "react-router-dom";
import { useSelector, shallowEqual } from "react-redux";
import SVG from "react-inlinesvg";
import { toAbsoluteUrl, checkIsActive } from "../../../../_helpers";

import { RolesTypes } from "../../../../../app/const";


export function AsideMenuList({ layoutProps }) {
  const location = useLocation();
  const getMenuItemActive = (url, hasSubmenu = false, exact) => {
    return checkIsActive(location, url, exact)
      ? ` ${!hasSubmenu && "menu-item-active"} menu-item-open menu-item-not-hightlighted`
      : "";
  };

  const { Roles } = useSelector(
    ({ auth }) => ({
      Roles: auth.user.authorities,
    }),
    shallowEqual
  );

  return (
    <>
      {/* begin::Menu Nav */}
      <ul className={`menu-nav ${layoutProps.ulClasses}`}>
        {/*begin::Dashboard*/}
        <li
          className={`menu-item ${getMenuItemActive("/dashboard", false)}`}
          aria-haspopup="true"
        >
          <NavLink className="menu-link" to="/dashboard">
            <span className="svg-icon menu-icon">
              <SVG src={toAbsoluteUrl("/media/svg/icons/Design/Layers.svg")} />
            </span>
            <span className="menu-text">Dashboard</span>
          </NavLink>
        </li>
        {/*end::Dashboard*/}

        {/* Embargos */}
        {/*begin::Embargos*/}
        { Roles.find(value => value.authority === RolesTypes.basic) &&
          <li
            className={`menu-item menu-item-submenu ${getMenuItemActive( "/embargos", true )}`}
            aria-haspopup="true"
            data-menu-toggle="hover"
          >
            <NavLink className="menu-link menu-toggle" to="/embargos">
              <span className="svg-icon menu-icon">
                <SVG src={toAbsoluteUrl("/media/svg/icons/files/File.svg")} />
              </span>
              <span className="menu-text">Embargos</span>
              <i className="menu-arrow" />
            </NavLink>
            <div className="menu-submenu ">
              <i className="menu-arrow" />
              <ul className="menu-subnav">
                <li className="menu-item  menu-item-parent" aria-haspopup="true">
                  <span className="menu-link">
                    <span className="menu-text">Embargos</span>
                  </span>
                </li>

                {/*begin::Upload*/}
                <li
                  className={`menu-item ${getMenuItemActive( "/embargos/upload", false, true )}`}
                  aria-haspopup="true"
                >
                  <NavLink className="menu-link" to="/embargos/upload">
                    <i className="menu-bullet menu-bullet-dot">
                      <span />
                    </i>
                    <span className="menu-text">Subir</span>
                  </NavLink>
                </li>
                {/*end::Upload*/}

                <li
                  className={`menu-item ${getMenuItemActive("/embargos/assigned-to-me", false, true)}`}
                  aria-haspopup="true"
                >
                  <NavLink className="menu-link" to="/embargos/assigned-to-me">
                    <i className="menu-bullet menu-bullet-dot">
                      <span />
                    </i>
                    <span className="menu-text">Embargos asignados</span>
                  </NavLink>
                </li>

                {/*begin::Checkout*/}
                <li
                  className={`menu-item menu-item-submenu ${getMenuItemActive("/embargos/checkout", true)}`}
                  aria-haspopup="true"
                  data-menu-toggle="hover"
                >

                  <NavLink
                    className="menu-link menu-toggle"
                    to="/embargos/checkout"
                  >
                    <i className="menu-bullet menu-bullet-dot">
                      <span />
                    </i>
                    <span className="menu-text">Revisar</span>
                    <i className="menu-arrow" />
                  </NavLink>
                  <div className="menu-submenu ">
                    <i className="menu-arrow" />
                    <ul className="menu-subnav">
                      {/* begin::processed */}
                      <li
                        className={`menu-item ${getMenuItemActive("/embargos/checkout/processed", false, true)}`}
                        aria-haspopup="true"
                      >
                        <NavLink
                          className="menu-link"
                          to="/embargos/checkout/processed"
                        >
                          <i className="menu-bullet menu-bullet-dot">
                            <span />
                          </i>
                          <span className="menu-text">Procesados</span>
                        </NavLink>
                      </li>
                      {/* begin::processed with errors */}
                      <li
                        className={`menu-item ${getMenuItemActive('/embargos/checkout/processed-with-error', false, true)}`}
                        aria-haspopup="true"
                      >
                        <NavLink
                          className="menu-link"
                          to='/embargos/checkout/processed-with-error'
                        >
                          <i className="menu-bullet menu-bullet-dot">
                            <span />
                          </i>
                          <span className="menu-text">Procesados con error</span>
                        </NavLink>
                      </li>
                      {/* end::confirm */}
                      {/* begin::confirm */}
                      <li
                        className={`menu-item ${getMenuItemActive("/embargos/checkout/confirm", false, true)}`}
                        aria-haspopup="true"
                      >
                        <NavLink
                          className="menu-link"
                          to="/embargos/checkout/confirm"
                        >
                          <i className="menu-bullet menu-bullet-dot">
                            <span />
                          </i>
                          <span className="menu-text">Confirmados</span>
                        </NavLink>
                      </li>
                      {/* end::confirm */}
                      {/* begin::unconfirmed */}
                      <li
                        className={`menu-item ${getMenuItemActive( "/embargos/checkout/unconfirmed", false, true)}`}
                        aria-haspopup="true"
                      >
                        <NavLink
                          className="menu-link"
                          to="/embargos/checkout/unconfirmed"
                        >
                          <i className="menu-bullet menu-bullet-dot">
                            <span />
                          </i>
                          <span className="menu-text">Por confirmar</span>
                        </NavLink>
                      </li>
                      {/* end::unconfirmed */}
                      {/* begin::in process */}
                      <li
                        className={`menu-item ${getMenuItemActive( "/embargos/checkout/in-process", false, true )}`}
                        aria-haspopup="true"
                      >
                        <NavLink
                          className="menu-link"
                          to="/embargos/checkout/in-process"
                        >
                          <i className="menu-bullet menu-bullet-dot">
                            <span />
                          </i>
                          <span className="menu-text">En proceso</span>
                        </NavLink>
                      </li>
                      {/* end::in process */}
                    </ul>
                  </div>
                </li>
              {/*end::Checkout*/}

              {/*begin::Notifications*/}
              <li
                  className={`menu-item menu-item-submenu ${getMenuItemActive("/embargos/notifications", true)}`}
                  aria-haspopup="true"
                  data-menu-toggle="hover"
              >
                <NavLink
                    className="menu-link menu-toggle"
                    to="/embargos/notifications"
                  >
                    <i className="menu-bullet menu-bullet-dot">
                      <span />
                    </i>
                    <span className="menu-text">Notificaciones</span>
                    <i className="menu-arrow" />
                </NavLink>
                <div className="menu-submenu ">
                  <i className="menu-arrow" />
                    <ul className="menu-subnav">
                      {/* begin::processed */}
                      <li
                        className={`menu-item ${getMenuItemActive("/embargos/notifications/processed", false, true)}`}
                        aria-haspopup="true"
                      >
                        <NavLink
                          className="menu-link"
                          to="/embargos/notifications/processed"
                        >
                          <i className="menu-bullet menu-bullet-dot">
                            <span />
                          </i>
                          <span className="menu-text">Procesadas</span>
                        </NavLink>
                      </li>
                      <li
                        className={`menu-item ${getMenuItemActive("/embargos/notifications/in-process", false, true)}`}
                        aria-haspopup="true"
                      >
                        <NavLink
                          className="menu-link"
                          to="/embargos/notifications/in-process"
                        >
                          <i className="menu-bullet menu-bullet-dot">
                            <span />
                          </i>
                          <span className="menu-text">En proceso</span>
                        </NavLink>
                      </li>
                    </ul>
                </div>
                

              </li>
              {/*end::Notifications*/}

              {/*begin::filters*/}
              <li
                  className={`menu-item menu-item-submenu ${getMenuItemActive( "/embargos/filters", true )}`}
                  aria-haspopup="true"
                  data-menu-toggle="hover"
                >

                  <NavLink
                    className="menu-link menu-toggle"
                    to="/embargos/filters"
                  >
                    <i className="menu-bullet menu-bullet-dot">
                      <span />
                    </i>
                    <span className="menu-text">Filtros</span>
                    <i className="menu-arrow" />
                  </NavLink>

                  <div className="menu-submenu ">
                    <i className="menu-arrow" />
                    <ul className="menu-subnav">
                      {/* begin::embargos filters */}
                      <li
                        className={`menu-item ${getMenuItemActive( "/embargos/filters", false, true )}`}
                        aria-haspopup="true"
                      >
                        <NavLink
                          className="menu-link"
                          to="/embargos/filters"
                        >
                          <i className="menu-bullet menu-bullet-dot">
                            <span />
                          </i>
                          <span className="menu-text">Por embargo</span>
                        </NavLink>
                      </li>
                      {/* end::embargos filters */}

                      {/* begin:: defendants*/}
                      <li
                        className={`menu-item ${getMenuItemActive( '/embargos/filters-defendants', false, true )}`}
                        aria-haspopup="true"
                      >
                        <NavLink
                          className="menu-link"
                          to='/embargos/filters-defendants'
                        >
                          <i className="menu-bullet menu-bullet-dot">
                            <span />
                          </i>
                          <span className="menu-text">Por demandado</span>
                        </NavLink>
                      </li>
                      {/* end:: defendants*/}
                    </ul>
                  </div>
                </li>
              {/*end::filters*/}

              <li
                className={`menu-item ${getMenuItemActive("/embargos/letters", false, true)}`}
                aria-haspopup="true"
              >
                <NavLink className="menu-link" to="/embargos/letters">
                  <i className="menu-bullet menu-bullet-dot">
                    <span />
                  </i>
                  <span className="menu-text">Cartas</span>
                </NavLink>
              </li>

            </ul>
          </div>
        </li> }
        {/*end::Embargos*/}

        {/* Usuarios */}
        {/* begin::Usuarios */}
        {Roles.find(value => value.authority === RolesTypes.admin) &&
          <li
            className={`menu-item menu-item-submenu ${getMenuItemActive( "/users", true )}`}
            aria-haspopup="true"
            data-menu-toggle="hover"
          >
            <NavLink className="menu-link menu-toggle" to="/users">
              <span className="svg-icon menu-icon">
                <SVG src={toAbsoluteUrl("/media/svg/icons/General/User.svg")} />
              </span>
              <span className="menu-text">Usuarios</span>
              <i className="menu-arrow" />
            </NavLink>
            <div className="menu-submenu ">
              <i className="menu-arrow" />
              <ul className="menu-subnav">
                <li className="menu-item  menu-item-parent" aria-haspopup="true">
                  <span className="menu-link">
                    <span className="menu-text">Usuarios</span>
                  </span>
                </li>

                {/*begin::list*/}
                <li
                  className={`menu-item ${getMenuItemActive("/users/list", false, true)}`}
                  aria-haspopup="true"
                >
                  <NavLink className="menu-link" to="/users/list">
                    <i className="menu-bullet menu-bullet-dot">
                      <span />
                    </i>
                    <span className="menu-text">Listar</span>
                  </NavLink>
                </li>
                {/*end::list*/}

                {/*begin::add*/}
                <li
                  className={`menu-item ${getMenuItemActive("/users/add", false, true)}`}
                  aria-haspopup="true"
                >
                  <NavLink className="menu-link" to="/users/add">
                    <i className="menu-bullet menu-bullet-dot">
                      <span />
                    </i>
                    <span className="menu-text">Crear usuario</span>
                  </NavLink>
                </li>
                {/*end::add*/}

                <li
                  className={`menu-item ${getMenuItemActive( "/users/assignment", false, true )}`}
                  aria-haspopup="true"
                >
                  <NavLink className="menu-link" to="/users/assignment">
                    <i className="menu-bullet menu-bullet-dot">
                      <span />
                    </i>
                    <span className="menu-text">Asignación de documentos</span>
                  </NavLink>
                </li>

              </ul>
            </div>

          </li>
        }
        {/* end::Usuarios */}

        {/* begin::config */}
        {Roles.find(value => value.authority === RolesTypes.admin) &&
          <li
            className={`menu-item menu-item-submenu ${getMenuItemActive( "/config", true)}`}
            aria-haspopup="true"
            data-menu-toggle="hover"
          >
            <NavLink className="menu-link menu-toggle" to="/users">
              <span className="svg-icon menu-icon">
                <SVG src={toAbsoluteUrl("/media/svg/icons/Tools/Tools.svg")} />
              </span>
              <span className="menu-text">Configuración</span>
              <i className="menu-arrow" />
            </NavLink>
            <div className="menu-submenu ">
              <i className="menu-arrow" />
              <ul className="menu-subnav">
                <li className="menu-item  menu-item-parent" aria-haspopup="true">
                  <span className="menu-link">
                    <span className="menu-text">Configuración</span>
                  </span>
                </li>
                <li
                  className={`menu-item ${getMenuItemActive("/config/identity-types", false, true)}`}
                  aria-haspopup="true"
                >
                  <NavLink className="menu-link" to="/config/identity-types">
                    <i className="menu-bullet menu-bullet-dot">
                      <span />
                    </i>
                    <span className="menu-text">Tipos de identificación</span>
                  </NavLink>
                </li>
                <li
                  className={`menu-item ${getMenuItemActive("/config/interval", false, true)}`}
                  aria-haspopup="true"
                >
                  <NavLink className="menu-link" to="/config/interval">
                    <i className="menu-bullet menu-bullet-dot">
                      <span />
                    </i>
                    <span className="menu-text">Intervalo</span>
                  </NavLink>
                </li>
              </ul>
            </div>
          </li>
        }
        {/* end::config */}

      </ul>

      {/* end::Menu Nav */}
    </>
  );
}
