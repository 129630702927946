import React, { Suspense, lazy } from "react";
import { Redirect, Switch, Route, useHistory } from "react-router-dom";
import { useSelector, shallowEqual } from "react-redux";

import { LayoutSplashScreen, ContentRoute } from "../_metronic/layout";
import { RolesTypes } from "./const";

//import { DashboardPage } from "./pages/DashboardPage";
import { BuilderPage } from "./pages/BuilderPage";
import HomePage from "./pages/HomePage";
import SystemPage from "./modules/System/SystemPage";

const GoogleMaterialPage = lazy(() =>
  import("./modules/GoogleMaterialExamples/GoogleMaterialPage")
);
const ReactBootstrapPage = lazy(() =>
  import("./modules/ReactBootstrapExamples/ReactBootstrapPage")
);
const ECommercePage = lazy(() =>
  import("./modules/ECommerce/pages/eCommercePage")
);
const UserProfilepage = lazy(() =>
  import("./modules/UserProfile/UserProfilePage")
);
const EmbargosPage = lazy(() =>
  import("./modules/Embargos/EmbargosPage")
);

const UsersPage = lazy(() =>
  import("./modules/Users/UsersPage")
);

export default function BasePage() {

  const { Roles, sessionExpired } = useSelector( ({auth}) => ({
        Roles: auth.user.authorities,
        sessionExpired: auth.sessionExpired,
    }),
    shallowEqual
  );
  const history = useHistory();

  React.useEffect(() => {
    if(sessionExpired) history.push("/logout");
  }, [sessionExpired, history]);

  return (
    <Suspense fallback={<LayoutSplashScreen />}>
      <Switch>
        {
          /* Redirect from root URL to /dashboard. */
          <Redirect exact from="/" to="/dashboard" />
        }
        <ContentRoute path="/dashboard" component={HomePage} />
        <ContentRoute path="/builder" component={BuilderPage} />

        <Route path="/embargos" component={EmbargosPage} />
        { Roles.find(value => value.authority === RolesTypes.admin) ?
          <>
            <Route path="/users" component={UsersPage} />
            <Route path="/config" component={SystemPage} />
          </>
        :
          <Redirect to="/dashboard" />
        }
        <Redirect to="error/error-v1" />
      </Switch>
    </Suspense>
  );
}
