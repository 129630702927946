import React from "react";
import {toAbsoluteUrl} from "../../_metronic/_helpers";

function HomePage() {
  return (
    <div className="contanier h-100">
      <div className="row h-100 justify-content-center align-items-center">
        <div className="col-50 align-self-center">
          <img alt="logotipo" src={toAbsoluteUrl("/media/logos/logotipo-seiz.png")}/>
        </div>
      </div>
    </div>
  );
}

export default HomePage;