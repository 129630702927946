
export const erros_code = {
    /* API errors */
    sessionExpired: "SESSION_EXPIRED",
    internalServerError: "INTERNAL_SERVER_ERROR",
    unexpectedError: "UNEXPECTED_ERROR",
    serverOutOfService: "SERVER_OUT_OF_SERVICE",

    /* Login errors */
    userLocked: "USER_LOCKED",
    userDisable: "USER_DISABLE",
    incorrectData: "INCORRECT_DATA",

    /* Embargo errors */ 
    notCancel: "NOT_CANCEL",
}

export const errorMessage = ( error_str ) => {

    switch ( error_str ) {
        /* API errors */
        case erros_code.sessionExpired:
            return "La sesión ha expirado";

        case erros_code.internalServerError:
            return "Error en el servidor";

        case erros_code.unexpectedError:
            return "Error inesperado";

        case erros_code.serverOutOfService:
            return "Servidor fuera de servicio";

        /* Login errors */
        case erros_code.userLocked:
            return "Usuario bloqueado";

        case erros_code.userDisable:
            return "Usuario deshabilitado";

        case erros_code.incorrectData:
            return "Usuario o contraseña incorrectos";

        /* Embargo errors */     
        case erros_code.notCancel:
            return "Debes esperar cinco minutos a partir de la hora de inicio para cancelar un proceso.";
    
        /* Default */
        default:
            return "Error inesperado (def)";
    }

}