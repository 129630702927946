import { createSlice } from "@reduxjs/toolkit";

const initState = {
    loading: false,
    letterTypesList: [],
    error: ""
}

const letterSlice = createSlice({
    name: "letters",
    initialState: initState,
    reducers: {
        setLetterTypesList: (state, action) => {
            const { data } = action.payload;
            state.letterTypesList = data;
        },
        setStartRequest: (state) => {
            state.loading = true
        },
        setFinishReques: (state) => {
            state.loading = false
        },
        setError: (state, action) => {
            const { data } = action.payload;
            state.error = data
        },
    }

});

export default letterSlice;