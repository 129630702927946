import { all } from "redux-saga/effects";
import { combineReducers } from "redux";

import * as auth from "../app/modules/Auth/_redux/authRedux";
import { embargosSlice } from "../app/modules/Embargos/_redux/embargosRedux";
import { usersSlice } from "../app/modules/Users/_redux/UsersRedux";
import { notificationSlice } from "../app/modules/General/_redux/fileRedux";
import filtersSlice from "../app/modules/Embargos/_redux/filters/FiltersSlice";
import letterSlice from "../app/modules/Embargos/_redux/letters/LetterSlice";

export const rootReducer = combineReducers({
  auth: auth.reducer,
  embargos: embargosSlice.reducer,
  users: usersSlice.reducer,
  notifications: notificationSlice.reducer,
  filters: filtersSlice.reducer,
  letters: letterSlice.reducer,
});

export function* rootSaga() {
    yield all([auth.saga()]);
}