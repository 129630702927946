import React, { useState, useEffect } from "react";

import { useSelector, shallowEqual } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import { LinearProgress, Button, Snackbar, TextField } from "@material-ui/core";

import MuiAlert from "@material-ui/lab/Alert";
import { getIdentityTypes, updateIdentityType } from "../../modules/Users/_redux/UsersCrud";

const Styles = makeStyles((_) => ({
  base: {
    padding: "50px",
    backgroundColor: "#fefefe",
  },
  buttonsSection: {
    width: "100%",
    display: "flex",
    flexDirection: "row",
    marginTop: 30,
  },
}));

const IdentityTypesPage = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [identityTypes, setIdentityTypes] = useState([]);
  const { authToken } = useSelector((state) => state.auth, shallowEqual);
  const [error, setError] = useState({ active: false, text: "" });
  const styles = Styles();

  useEffect(() => {
    requireTypes();
  }, []);

  const showErrorAlert = (text) => {
    setError({ active: true, text });
  };

  const requireTypes = async () => {
    setIsLoading(true);
    try {
      const response = await getIdentityTypes(authToken);
      if (response.status >= 500)
        throw new Error("Ocurrió un error interno en el servidor");
      const data = await response.json();
      setIdentityTypes(data);
    } catch (error) {
      showErrorAlert(error.message);
    } finally {
      setIsLoading(false);
    }
  };

  const getRepeatedValues = () => {
    let repeatedValues = [];
    identityTypes.map((type, index) => {
      let cloneIdentity = [...identityTypes];
      cloneIdentity.splice(index, 1);
      if (cloneIdentity.find((cloneType) => cloneType.idConexion === type.idConexion)) {
        repeatedValues.push(type.idConexion);
      }
    });
    return [...new Set(repeatedValues)];
  };

  const areNumericValues = () => {
    let areAllNumerics = true;
    identityTypes.map(({idConexion}) => {
      areAllNumerics = areAllNumerics && idConexion.toString().length && !isNaN(idConexion)
    })
    return areAllNumerics;
  }

  const saveTypes = async () => {
    setIsLoading(true);
    try {
      if (!areNumericValues()) throw new Error('Todos los valores deben ser numericos')
      const repeatedValues = getRepeatedValues();
      if (repeatedValues.length > 0) throw new Error(`Los valores deben ser unicos. Se usan más de una vez ${repeatedValues}`)
      
      const updateResponse = await updateIdentityType( identityTypes , authToken);
      if (updateResponse.status >= 400)
        throw new Error(`Error al actualizar`);

      const response = await getIdentityTypes(authToken);
      if (response.status >= 500)
        throw new Error("Ocurrió un error interno en el servidor");
      const data = await response.json();
      setIdentityTypes(data);
    } catch (error) {
      showErrorAlert(error.message);
    } finally {
      setIsLoading(false);
    }
  };

  const handleChangeValue = (value, indexItem) => {
    if (!value) showErrorAlert("El valor debe ser un número");
    if (!isNaN(value)) {
      let cloneTypes = [...identityTypes];
      cloneTypes[indexItem].idConexion = !value.length ? "" : Number(value);
      setIdentityTypes(cloneTypes);
    } else {
      showErrorAlert("El valor debe ser un número");
    }
  };

  const Alert = (props) => (
    <MuiAlert elevation={6} variant="filled" {...props} />
  );

  const snackBarError = () => {
    const handleClose = () => setError({ active: false, text: "" });
    return (
      <Snackbar
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
        open={error.active}
        autoHideDuration={5000}
        onClose={handleClose}
      >
        <Alert onClose={handleClose} severity="error">
          {error.text}
        </Alert>
      </Snackbar>
    );
  };

  return (
    <div className={styles.base}>
      {snackBarError()}
      <h4>Asignación de tipos de identificación</h4>
      <hr />
      <br />
      {isLoading ? (
        <div className="w-100">
          <LinearProgress color="secondary" />
        </div>
      ) : (
        <>
          <div className="row no-gutters justify-content-center">
            {identityTypes.length &&
              identityTypes.map((type, indexType) => (
                <div
                  className="col-12 col-md-4 mt-5"
                  key={`user-${type.tipo}-${indexType}`}
                >
                  <TextField
                    type="number"
                    color="secondary"
                    value={type.idConexion != null ? type.idConexion : ""}
                    id="outlined-basic"
                    label={type.tipo}
                    variant="outlined"
                    onChange={(event) =>
                      handleChangeValue(event.target.value, indexType)
                    }
                  />
                </div>
              ))}
          </div>
          <div className={styles.buttonsSection}>
            <Button
              variant="contained"
              style={{ marginLeft: "auto" }}
              color="secondary"
              onClick={saveTypes}
            >
              Guardar
            </Button>
          </div>
        </>
      )}
    </div>
  );
};

export default IdentityTypesPage;
