import React from 'react';
import Snackbar from '@material-ui/core/Snackbar';
import { Notifications } from "./utils/Notifications";
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from "react-redux";

import { notificationActions } from "../_redux/fileRedux"; 

export default function NotificationBar() {
  const { open, message, variant } = useSelector(state => state.notifications);
  const dispatch = useDispatch();

  function handleClose(event, reason) {
    if (reason === 'clickaway') {
      return;
    }
    dispatch(notificationActions.setNotificationField("open", false));
  }

  return(
    <Snackbar
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'right',
      }}
      open={open}
      autoHideDuration={3000}
      onClose={handleClose}
    >
      <Notifications 
        onClose={handleClose}
        variant={variant}
        message={message}
      />
    </Snackbar>
  );
}

Notifications.propTypes = {
  message: PropTypes.node,
  open: PropTypes.bool,
  setOpen: PropTypes.func,
  variant: PropTypes.oneOf(['success', 'warning', 'error', 'info']).isRequired,
};