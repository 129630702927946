import makeRequest from "../../General/_redux/Crud";
import { getQueryParams } from "../../../const";

export const PATH_USERS = "/api/v1/users";
export const PATH_IDENTITY_TYPES = "/api/v1/id-type";
export const PATH_USERS_LIST = PATH_USERS + "/list";
export const PATH_USERS_CREATE = PATH_USERS + "/";
export const PATH_USERS_EDIT = PATH_USERS + "/edit";
export const PATH_USERS_STATE = PATH_USERS + "/state";

export function UsersList(props, authToken) {
  return makeRequest({
    path: PATH_USERS_LIST + getQueryParams(props),
    method: "GET",
    headers: new Headers({ Authorization: "Bearer " + authToken }),
  });
}

export function getUser(id, authToken) {
  return makeRequest({
    path: PATH_USERS + `/${id}`,
    method: "GET",
    headers: new Headers({ Authorization: "Bearer " + authToken }),
  });
}

export function createUser(props, authToken) {
  return makeRequest({
    path: PATH_USERS_CREATE,
    method: "POST",
    headers: new Headers({
      Authorization: "Bearer " + authToken,
      "Content-Type": "application/json",
    }),
    body: JSON.stringify({
      email: props.email,
      password: props.password,
      name: props.name,
      surname: props.surname,
      username: props.username,
      authorities: props.authorities,
      embTypes: props.embTypes,
      enabled: true,
    }),
  });
}

export function getDocumentsTypeByUser(id, authToken) {
  return makeRequest({
    path: PATH_USERS + `/${id}`,
    method: "GET",
    headers: new Headers({ Authorization: "Bearer " + authToken }),
  });
}

export function updateUserEmbTypes(username, data, authToken) {
  return makeRequest({
    path: PATH_USERS + "/edit" + getQueryParams({ username }),
    method: "POST",
    headers: new Headers({
      Authorization: "Bearer " + authToken,
      "Content-Type": "application/json",
    }),
    body: JSON.stringify(data),
  });
}

export function updateUser(username, props, authToken) {
  return makeRequest({
    path: PATH_USERS_EDIT + `?username=${username}`,
    method: "POST",
    headers: new Headers({
      Authorization: "Bearer " + authToken,
      "Content-Type": "application/json",
    }),
    body: JSON.stringify({ ...props }),
  });
}

export function updateUserPassword(userId, password, authToken) {
  return makeRequest({
    path: PATH_USERS + `/${userId}/password`,
    method: "PUT",
    headers: new Headers({
      Authorization: "Bearer " + authToken,
      "Content-Type": "application/json",
    }),
    body: JSON.stringify(password),
  });
}

export function userState( props, authToken) {
  return makeRequest({
    path: PATH_USERS_STATE + getQueryParams(props),
    method: "POST",
    headers: new Headers({
      Authorization: "Bearer " + authToken,
      "Content-Type": "application/json",
    }),
  });
}

export function getIdentityTypes(authToken) {
  return makeRequest({
    path: PATH_IDENTITY_TYPES,
    method: "GET",
    headers: new Headers({ Authorization: "Bearer " + authToken }),
  });
}

export function updateIdentityType(data, authToken) {
  return makeRequest({
    path: PATH_IDENTITY_TYPES,
    method: "POST",
    headers: new Headers({
      Authorization: "Bearer " + authToken,
      "Content-Type": "application/json",
    }),
    body: JSON.stringify(data),
  });
}
