
import * as authRedux from "./../modules/Auth/_redux/authRedux";
import { erros_code } from "./errors_code";

const baseErrors = ( api_reponse ) => {

    if(api_reponse.status === 500) {
        throw new Error( erros_code.internalServerError );
    }

    if(api_reponse.status === 502) {
        throw new Error( erros_code.serverOutOfService );
    }

}

export const checkRequestErrors = ( dispatch, api_reponse ) => {

    baseErrors( api_reponse );
    
    if( api_reponse.status === 440 || api_reponse.status === 403 ) {
        dispatch(authRedux.actions.setSessionExpired());
        throw new Error( erros_code.sessionExpired );
    }

    if(!api_reponse.ok) throw new Error( erros_code.unexpectedError );
}

export const checkLoginErrors =  async ( api_reponse ) => {

    baseErrors( api_reponse );

    if(api_reponse.status === 401) {
        const data = await api_reponse.json();
        if ( data.error === "User is disabled" ){
            throw new Error( erros_code.userLocked );

        }else if ( data.error === "User account is locked" ){
            throw new Error( erros_code.userDisable );

        }else{
            throw new Error( erros_code.incorrectData );
        }
    }

    if(!api_reponse.ok) throw new Error( erros_code.unexpectedError );

}

export const checkInProcessErrors = ( api_reponse ) => {

    baseErrors( api_reponse );
    
    if( api_reponse.status >= 400  ) {
        throw new Error( erros_code.notCancel );;
    }

    if(!api_reponse.ok) throw new Error( erros_code.unexpectedError );
}