import { createSlice } from "@reduxjs/toolkit";

import * as authRedux from "../../Auth/_redux/authRedux";
import * as requestFromServer from "./UsersCrud";
import { notificationActions } from "../../General/_redux/fileRedux"; 
import { checkRequestErrors } from "../../../const/api_erros";

const initialUserState = {
  usersList: [],
  edit_user: {},
};

const actionTypes = {
  set_users_list: "SET_USERS_LIST",
};

const getUsersList = props => (dispatch, getState) => {
  return requestFromServer.UsersList({...props, size: 1000}, getState().auth.authToken)
  .then(response => {
    if(response.status === 440) dispatch(authRedux.actions.setSessionExpired());
    if(!response.ok) throw new Error("Ocurrió un error");
    return response.json();
  })
  .then(response => {
    dispatch(
      usersSlice.actions.setUsersList(
        { type: actionTypes.set_users_list, table: response }
      )
    );
  })
  .catch(err => {
    dispatch(notificationActions.setNotification(err.message, "error"));
  });
};

const getUser = id => (dispatch, getState) => {
  return requestFromServer.getUser(id, getState().auth.authToken)
  .then(response => {
    if(response.status === 440) dispatch(authRedux.actions.setSessionExpired());
    if(!response.ok) throw new Error("Ocurrió un error");
    return response.json();
  })
  .then(response => {
    dispatch(
      usersSlice.actions.setUser(
        { type: actionTypes.set_user, user: response }
      )
    );
  })
  .catch(err => {
    dispatch(notificationActions.setNotification(err.message, "error"));
  });
};

const createUser = props => (dispatch, getState) => {
  return requestFromServer.createUser(props, getState().auth.authToken)
  .then(response => {
    if(response.status === 440) dispatch(authRedux.actions.setSessionExpired());
    if(!response.ok) throw new Error("Ocurrió un error");
    dispatch(notificationActions.setNotification("Usuario creado exitosamente", "success"));
    return true;
  })
  .catch(err => {
    dispatch(notificationActions.setNotification(err.message, "error"));
    return false;
  });
};

const updateUser = (username, props) => (dispatch, getState) => {
  return requestFromServer.updateUser(username, props, getState().auth.authToken)
  .then(response => {
    if(response.status === 440) dispatch(authRedux.actions.setSessionExpired());
    if(!response.ok) throw new Error("Ocurrió un error");
    dispatch(notificationActions.setNotification("Usuario actualizado exitosamente", "success"));
    return true;
  })
  .catch(err => {
    dispatch(notificationActions.setNotification(err.message, "error"));
    return false;
  });
};

const updateUserState = ( userState ) => (dispatch, getState) => {
  return requestFromServer.userState(userState, getState().auth.authToken)
  .then(response => {
    if(response.status === 440) dispatch(authRedux.actions.setSessionExpired());
    if(!response.ok) throw new Error("Ocurrió un error");
    dispatch(notificationActions.setNotification("Usuario actualizado exitosamente", "success"));
    return true;
  })
  .catch(err => {
    dispatch(notificationActions.setNotification(err.message, "error"));
    return false;
  });
};

const updateUserPassword = (userId, passwordBody) => (dispatch, getState) => {
  return requestFromServer.updateUserPassword(userId, passwordBody, getState().auth.authToken)
  .then(response => {
    checkRequestErrors(dispatch, response);
    dispatch(notificationActions.setNotification("Contraseña actualizada exitosamente", "success"));
    return true;
  })
  .catch(err => {
    dispatch(notificationActions.setNotification(err.message, "error"));
    return false;
  });
};

export const actions = {
  getUsersList,
  createUser,
  updateUser,
  getUser,
  updateUserState,
  updateUserPassword,
};

export const usersSlice = createSlice({
  name: "users",
  initialState: initialUserState,
  reducers: {
    setUsersList: (state, action) => {
      const { table } = action.payload;
      state.usersList = table;
    },
    setUser: (state, action) => {
      const { user } = action.payload;
      state.edit_user = user;
    }, 
  }
});
