import makeRequest from "../../General/_redux/Crud";
import { getQueryParams } from "../../../const";
import MainService from "../../General/Services";

export const PATH_EMBARGOS = "/api/v1/embargos";
export const PATH_DEMANDADOS = "/api/v1/demandados";
export const PATH_DEMANDANTES = "/api/v1/demandantes";
export const PATH_LETTERS="/api/v1/letters";
export const PATH_WORKS="/api/v1/work";
export const PATH_SPREADSHEET = "/api/v1/spreadsheet";
export const PATH_NOTIFICATIONS = "/api/v1/notifications";

export const PATH_NOTIFICATIONS_LIST = PATH_NOTIFICATIONS + '/list';
export const PATH_NOTIFICATIONS_UPDATE_STATUS = PATH_NOTIFICATIONS + '/update-status';
export const PATH_SPREADSHEET_OBTAIN = PATH_SPREADSHEET + "/obtain-unique";
export const PATH_EMBARGOS_LIST = PATH_EMBARGOS + "/list";
export const PATH_EMBARGOS_UPLOAD = PATH_EMBARGOS + "/upload";
export const PATH_EMBARGOS_UPDATE_DATASET = PATH_EMBARGOS + "/update-dataset";
export const PATH_EMBARGOS_CHECK_EXCEL = PATH_EMBARGOS + "/check-defendants-file";
export const PATH_EMBARGOS_REPORT =
  PATH_EMBARGOS + "/report/processed-defendants";
export const PATH_DEMANDADOS_EXCEL_UPLOAD = PATH_EMBARGOS + "/uploadDemandados";
export const PATH_DEMANDADOS_EXCEL_MAPPING = PATH_EMBARGOS + "/loadDemandados";
export const PATH_EMBARGOS_CONFIRM = PATH_EMBARGOS + "/confirm";
export const PATH_EMBARGOS_EXECUTE = PATH_EMBARGOS + "/execute-embargo";
export const PATH_DEMANDADOS_LIST = PATH_DEMANDADOS + "/list";
export const PATH_DEMANDADOS_SAVE = PATH_DEMANDADOS + "/save";
export const PATH_DEMANDADOS_TABLE = PATH_DEMANDADOS + "/seiz/extractTable";
export const PATH_DEMANDADOS_FUSE = PATH_DEMANDADOS + "/fuse-repeated";
export const PATH_DEMANDADOS_REPEATED = PATH_DEMANDADOS + "/repeated";
export const PATH_EMBARGOS_LETTERS = PATH_LETTERS + "/generate";

export const PATH_DEMANDANTES_SAVE = PATH_DEMANDANTES + "/save";
export const PATH_COUNT_EMBARGOS_LIST = PATH_EMBARGOS + "/count";
export const PATH_NOTIFY_EMBARGO = PATH_EMBARGOS + "/notify"

class EmbargosService extends MainService {
  constructor(authToken) {
    super(authToken);
  }

  get(props) {
    return makeRequest({
      path: PATH_EMBARGOS_LIST + getQueryParams(props),
      method: "POST",
      headers: this.headers,
    });
  }
}

export function getEmailNotificationList(props, authToken) {
  return makeRequest({
    path: PATH_NOTIFICATIONS_LIST + getQueryParams(props),
    method: "GET",
    headers: new Headers({ Authorization: "Bearer " + authToken }),
  });
}

export function getNotificationUpdateStatus(authToken) {
  return makeRequest({
    path: PATH_NOTIFICATIONS_UPDATE_STATUS,
    method: "GET",
    headers: new Headers({ Authorization: "Bearer " + authToken }),
  });
}

export function notifyEmbargo(id, email, authToken) {
  console.log("EMAIL", email);
  return makeRequest({
    path: PATH_NOTIFY_EMBARGO + `/${id}` + getQueryParams(email),
    method: "POST",
    headers: new Headers({ Authorization: "Bearer " + authToken }),
  })
}


// export function getEmbargosInProcess(props, authToken) {
//   return makeRequest({
//     path: PATH_WORKS + '?tipo.equal=ENVIO_EMAIL_AUTOMATICO' + getQueryParams(props),
//     method: "GET",
//     headers: new Headers({ Authorization: "Bearer " + authToken }),
//   });
// }

export function getEmbargosList(props, authToken) {
  return makeRequest({
    path: PATH_EMBARGOS_LIST + getQueryParams(props),
    method: "GET",
    headers: new Headers({ Authorization: "Bearer " + authToken }),
  });
}

export function getEmbargosInProcess(props, authToken) {
  return makeRequest({
    path: PATH_WORKS + getQueryParams(props),
    method: "GET",
    headers: new Headers({ Authorization: "Bearer " + authToken }),
  });
}

export function cancelEmbargoInProcess(props, authToken) {
  return makeRequest({
    path: PATH_WORKS + "/stop" + getQueryParams(props),
    method: "POST",
    headers: new Headers({ Authorization: "Bearer " + authToken }),
  });
}

export function getEmbargosListCount(props, authToken) {
  return makeRequest({
    path: PATH_COUNT_EMBARGOS_LIST + getQueryParams(props),
    method: "GET",
    headers: new Headers({ Authorization: "Bearer " + authToken }),
  });
}

export function getEmbargoData(id, authToken) {
  return makeRequest({
    path: PATH_EMBARGOS + `/${id}`,
    method: "GET",
    headers: new Headers({ Authorization: "Bearer " + authToken }),
  });
}

export function getDemandadosList(props, authToken) {
  return makeRequest({
    path: PATH_DEMANDADOS_LIST + getQueryParams(props),
    method: "GET",
    headers: new Headers({ Authorization: "Bearer " + authToken }),
  });
}

export function getDemandadosTable(props, authToken, body) {
  return makeRequest({
    path: PATH_DEMANDADOS_TABLE + getQueryParams(props),
    method: "POST",
    headers: new Headers({
      Authorization: "Bearer " + authToken,
      "Content-Type": "application/json",
    }),
    body: JSON.stringify({
      boundingPoly: body.boundingPoly,
      extract: "true",
      hint: body.hint,
      horizontalLines: body.horizontalLines,
      verticalLines: body.verticalLines,
      pageNumber: body.page,
      keyColumns: body.keyColumns,
    }),
  });
}

export function getEmbargosFilesBetween(props, authToken) {
  return makeRequest({
    path: PATH_EMBARGOS_REPORT + getQueryParams(props),
    method: "GET",
    headers: new Headers({ Authorization: "Bearer " + authToken }),
  });
}

export function getLetterFiles(props, authToken) {
  return makeRequest({
    path: PATH_EMBARGOS_LETTERS + getQueryParams(props),
    method: "GET",
    headers: new Headers({ Authorization: "Bearer " + authToken }),
  });
}

export function getLetterFile(id, authToken) {
  return makeRequest({
    path: PATH_LETTERS + `/${id}`,
    method: "GET",
    headers: new Headers({ Authorization: "Bearer " + authToken }),
  });
}

export function UploadEmbargos(files, authToken) {
  let formData = new FormData();
  for (let i = 0; i < files.length; i++) {
    formData.append("files", files[i]);
  }
  return makeRequest({
    path: PATH_EMBARGOS_UPLOAD,
    method: "POST",
    headers: new Headers({ Authorization: "Bearer " + authToken }),
    body: formData,
  });
}

export function uploadDemandadosExcel(idEmbargo, file, authToken) {
  let formData = new FormData();
  formData.append("file", file);
  return makeRequest({
    path: PATH_DEMANDADOS_EXCEL_UPLOAD + getQueryParams({ idEmbargo }),
    method: "POST",
    headers: new Headers({ Authorization: "Bearer " + authToken }),
    body: formData,
  });
}

export function confirmEmbargo(formData, authToken) {
  return makeRequest({
    path: PATH_EMBARGOS_CONFIRM,
    method: "POST",
    headers: new Headers({
      Authorization: "Bearer " + authToken,
      "Content-Type": "application/json",
    }),
    body: JSON.stringify(formData),
  });
}

export function deleteEmbargo(id, authToken) {
  return makeRequest({
    path: PATH_EMBARGOS + `/${id}`,
    method: "DELETE",
    headers: new Headers({ Authorization: "Bearer " + authToken }),
  });
}

export function uploadExcelMapping(idEmbargo, mappingData, authToken) {
  return makeRequest({
    path: PATH_DEMANDADOS_EXCEL_MAPPING + getQueryParams({ idEmbargo }),
    method: "POST",
    headers: new Headers({
      Authorization: "Bearer " + authToken,
      "Content-Type": "application/json",
    }),
    body: JSON.stringify(mappingData),
  });
}

export function loadDemandados(idEmbargo, mappingData, authToken) {
  return makeRequest({
    path: PATH_DEMANDADOS_EXCEL_MAPPING + getQueryParams({ idEmbargo }),
    method: "POST",
    headers: new Headers({
      Authorization: "Bearer " + authToken,
      "Content-Type": "application/json",
    }),
    body: JSON.stringify(mappingData),
  });
}

export function CheckDefendantsFiles( params, mappingData, authToken) {
  return makeRequest({
    path: PATH_EMBARGOS_CHECK_EXCEL + getQueryParams(params),
    method: "POST",
    headers: new Headers({
      Authorization: "Bearer " + authToken,
      "Content-Type": "application/json",
    }),
    body: JSON.stringify(mappingData),
  });
}

export function executeEmbargo(idEmbargo, authToken) {
  return makeRequest({
    path: PATH_EMBARGOS_EXECUTE + getQueryParams({ idEmbargo }),
    method: "POST",
    headers: new Headers({Authorization: "Bearer " + authToken,}),
  });
}

export function deleteAllDefendants(idEmbargo, authToken) {
  return makeRequest({
    path: PATH_DEMANDADOS + getQueryParams({ idEmbargo }),
    method: "DELETE",
    headers: new Headers({Authorization: "Bearer " + authToken,}),
  });
}

export function getSpreadsheetKeys( column ,idEmbargo, authToken) {
  return makeRequest({
    path: PATH_SPREADSHEET_OBTAIN + getQueryParams({ column, idEmbargo }),
    method: "POST",
    headers: new Headers({Authorization: "Bearer " + authToken,}),
  });
}

export function saveDemandados(props, authToken) {
  return makeRequest({
    path: PATH_DEMANDADOS_SAVE,
    method: "POST",
    headers: new Headers({
      Authorization: "Bearer " + authToken,
      "Content-Type": "application/json",
    }),
    body: JSON.stringify({
      demandados: props.defendants,
      idEmbargo: props.idEmbargo,
    }),
  });
}

export function deleteDemandado(id, authToken) {
  return makeRequest({
    path: PATH_DEMANDADOS + `/${id}`,
    method: "DELETE",
    headers: new Headers({Authorization: "Bearer " + authToken,}),
  });
}

export function saveDemandantes(props, authToken) {
  return makeRequest({
    path: PATH_DEMANDANTES_SAVE,
    method: "POST",
    headers: new Headers({
      Authorization: "Bearer " + authToken,
      'Content-Type': 'application/json',
    }),
    body: JSON.stringify({
      demandantes: props.plaintiffs,
      idEmbargo : props.idEmbargo,
    }),
  });
}

export function deleteDemandante(id, authToken) {
  return makeRequest({
    path: PATH_DEMANDANTES + `/${id}`,
    method: "DELETE",
    headers: new Headers({ Authorization: "Bearer " + authToken }),
  });
}

export function getEmbargosByUserName(username, authToken) {
  return makeRequest({
    path: PATH_EMBARGOS_LIST + getQueryParams({ assignedTo: username }),
    method: "GET",
    headers: new Headers({ Authorization: "Bearer " + authToken }),
  });
}

export function updateDataset(props, authToken) {
  return makeRequest({
    path: PATH_EMBARGOS_UPDATE_DATASET + getQueryParams({ idEmbargo: props.idEmbargo }),
    method: "PUT",
    headers: new Headers({
      Authorization: "Bearer " + authToken,
      "Content-Type": "application/json",
    }),
    body: JSON.stringify(props.fields)
  });
}

export function postFuseRepeated( idEmbargo, authToken ) {
  return makeRequest({
    path: PATH_DEMANDADOS_FUSE + getQueryParams({ idEmbargo }),
    method: "POST",
    headers: new Headers({Authorization: "Bearer " + authToken,}),
  });
}

export function getRepeated( idEmbargo, authToken  ) {
  return makeRequest({
    path: PATH_DEMANDADOS_REPEATED + getQueryParams({ idEmbargo }),
    method: "GET",
    headers: new Headers({ Authorization: "Bearer " + authToken }),
  });
}