import axios from "axios";
import makeRequest from "../../General/_redux/Crud";

export const LOGIN_URL = "/api/login";
export const REGISTER_URL = "api/auth/register";
export const REQUEST_PASSWORD_URL = "api/auth/forgot-password";

export const ME_URL = "api/me";

export function login(email, password) {
  //return axios.post(LOGIN_URL, { email, password });
  return makeRequest({
    path: LOGIN_URL,
    method: "POST",
    body: JSON.stringify({
      username: email,
      password
    })
  });
}

export function register(email, fullname, username, password) {
  return axios.post(REGISTER_URL, { email, fullname, username, password });
}

export function requestPassword(email) {
  return axios.post(REQUEST_PASSWORD_URL, { email });
}

export function getUserByToken() {
  // Authorization head should be fulfilled in interceptor.
  return axios.get(ME_URL);
}
