import { isEmpty } from "lodash";
import moment from "moment";

// export const MAIN_URL = "https://bancow.finseiz.com";
export const MAIN_URL = "http://35.188.216.232";
export const API_PATH = "/api/v1";
export const URL = MAIN_URL;

export const paginateProps = {
  currentPage: 0,
  sizePerPage: 10,
  sort: ["id", "asc"]
};

export const inputTypes = {
  select: "SELECT",
  text: "TEXT",
  date: "DATE"
};

export const embTypes = {
  coactivo: "COACTIVO", 
  judicial: "JUDICIAL",
};

export const embStates = {
  confirmed: "CONFIRMADO", 
  unconfirmed: "SIN_CONFIRMAR",
  processed: "PROCESADO",
  processedWithErrors: "PROCESADO_CON_ERRORES"
};

export const defendantState = {
  processed: "PROCESADO",
  processedWithErrors: "PROCESADO_CON_ERRORES"
};

export const embRequestType = {
  embargo: "EMBARGO", 
  desembargo: "DESEMBARGO",
  requerimiento: "REQUERIMIENTO",
};

export const boundingState = {
  init: "INITIAL",
  add_words: "ADD_WORDS",
  select_table: "SELECT_TABLE",
  view_table: "VIEW_TABLE",
};

export const RolesTypes = {
  admin: "ROLE_ADMIN",
  basic: "ROLE_BASIC_USER",
};

export const formatterPeso = new Intl.NumberFormat('es-CO', {
  style: 'currency',
  currency: 'COP',
  minimumFractionDigits: 0
});

export function getQueryParams(filters) {
  let ans = "";
  let first = true;
  for (const filter in filters) {
    if (filters[filter] !== "") {
      var str;
      if (first) [str, first] = ["?", false];
      else str = "&";
      
      switch (filter) {
        case "idEmbargo":
          ans += str + `idEmbargo=${filters[filter]}`;
          break;
        case "id":
          ans += str + `idEmbargo=${filters[filter]}`;
          break;
        case "startDate":
          ans += str + `processedDate.greaterThanOrEqualTo=${filters[filter]}T00:00:00.000-05:00`;
          break;
        case "endDate":
          ans += str + `processedDate.lessThanOrEqualTo=${filters[filter]}T23:59:00.000-05:00`;
          break;
        case "estadoEmbargo":
          if(!isEmpty(filters[filter])) {
            ans += str + `estadoEmbargo.in=${filters[filter][0]}`;
            let i = 1;
            while (i < filters[filter].length) {
              ans += `&estadoEmbargo.in=${filters[filter][i]}`;
              i++;
            }
          }
          break;
        case "estado":
          ans += str + `estado.equal=${filters[filter]}`;
          break;
        case "size":
          ans += str + `size=${filters[filter]}`;
          break;
        case "currentPage":
          ans += str + `page=${filters[filter]}`;
          break;
        case "sort":
          if ( Array.isArray( filters[filter] ) === true ){
            filters[filter].forEach( (sort) => {
              ans += str + `sort=${sort}`;
            });
          }else{
            ans += str + `sort=${filters[filter]}`;
          }
          break;
        case "defendants":
          ans += str + `nombres=${filters[filter]}`;
          break;
        case "defendantsID":
          ans += str + `identificacion=${filters[filter]}`;
          break;
        case "embargoType":
          ans += str + `tipoEmbargo=${filters[filter]}`;
          break;
        case "idCancel":
          ans += str + `id=${filters[filter]}`;
          break;
        case "startDateDef":
          ans += str + `fechaProcesamiento.greaterThanOrEqualTo=${filters[filter]}T00:00:00.000-05:00`;
          break;
        case "endDateDef":
          ans += str + `fechaProcesamiento.lessThanOrEqualTo=${filters[filter]}T23:59:00.000-05:00`;
          break;
        case "workType":
          ans += str + `tipo.equal=${filters[filter]}`;
          break;
        case "workType.notEqual":
          ans += str + `tipo.notEqual=${filters[filter]}`;
          break;
        case "notificationId":
          ans += str + `embargoId=${filters[filter]}`;
          break;
        case "pageNumber":
          ans += str + `pageNumber=${filters[filter]}`;
          break;
        case "pageSize":
          ans += str + `pageSize=${filters[filter]}`;
          break;
        case "paged":
          ans += str + `paged=${filters[filter]}`;
          break;
        case "sorted":
          ans += str + `sort.sorted=${filters[filter]}`;
          break;
        case "email":
          ans += str + `email=${filters[filter]}`;
          break;
        default: 
          ans += str + `${filter}=${filters[filter]}`;
          break;  
      }
      
    }
  }
  return ans;
}

export const simpleDateParse = (value, emptyMessage) => value ? moment(value).format("DD-MM-YYYY") : (emptyMessage ?? "");

export const simpleDateAndHourParse = (value, emptyMessage) => value ? moment(value).format("DD-MM-YYYY h:mm") : (emptyMessage ?? "");