
import { API_PATH } from "../../../const";
import makeRequest from "../../General/_redux/Crud";

const SCHEDULE = API_PATH + "/schedule";

export const getIntervalValue = ( authToken ) => {
    return makeRequest({
        path: SCHEDULE,
        method: "GET",
        headers: new Headers({ Authorization: "Bearer " + authToken }),
    });
}

export const postIntervalValue = ( value, authToken ) => {
    return makeRequest({
        path: SCHEDULE + "?delayInterval=" + value,
        method: "POST",
        headers: new Headers({ Authorization: "Bearer " + authToken }),
    });
}
