import { createSlice } from "@reduxjs/toolkit";

import * as requestFromServer from "./embargosCrud";

import { notificationActions } from "../../General/_redux/fileRedux"; 
import { boundingState, embTypes } from "../../../const";
import { checkInProcessErrors, checkRequestErrors } from "./../../../const/api_erros";
import { errorMessage } from "../../../const/errors_code";

const queryString = require('query-string');

export const ExecutionModeTypes = {
  auto: "AUTO",
  manual: "MANUAL",
}

const initCurrentEmbargo = {
  data: {},
  document: { file: null, type: undefined },
  json: null,
  json1: null,
  currentBounding: {
    state: boundingState.init,
    points: [],
    rectangle: {x: 0, y: 0, width: 0, height: 0, vertices: []},
    pointVerticalLines: [],
    pointHorizontalLines: [],
    executionMode: ExecutionModeTypes.auto,
    hint: "LINES"
  },
  defendantsList: [],
  plaintiffsList: [],
  currentPage: 1,
  numPages: 1,
  defendantsTotalCount: 0,
  boundingWords: "",
  onFocus: {
    field: undefined,
    id: undefined,
    part: undefined,
    index: undefined,
    formField: undefined
  },
  dataTable: {}
}

const inititalEmbargosState = {
  embargosList: [],
  totalCount: 0,
  embargosService: undefined,
  uploading: false,
  currentEmbargo: initCurrentEmbargo,
};

const actionTypes = {
  set_embargos_list: "SET_EMBARGOS_LIST",
  set_service: "SET_SERVICE",
  set_current_embargo: "SET_CURRENT_EMBARGO",
  set_embargo: "SET_EMBARGO",
  update_jsonl: "UPDATE_JSONL"
};

// setter - embargosList
const setEmbargosList = table => dispatch => {
  dispatch(embargosSlice.actions.setEmbargosList({ type: actionTypes.set_embargos_list, table }));
};

// setter - embargosService
const setEmbargosService = embargoService => dispatch => {
  dispatch(embargosSlice.actions.setService({ type: actionTypes.set_service, embargoService }));
};

const setEmbargo = (field, data) => dispatch => {
  dispatch(embargosSlice.actions.setEmbargo({ type: actionTypes.set_embargo, field, data }));
};

// setter - currentEmbargo
const setCurrentEmbargo = (field, data) => dispatch => {
  dispatch(embargosSlice.actions.setCurrentEmbargo({ type: actionTypes.set_current_embargo, field, data }));
};

// clean - currentEmbargo
const cleanCurrentEmbargo = () => dispatch => {
  dispatch(embargosSlice.actions.cleanCurrentEmbargo() );
};

// setter - jsonl
const updateJSONL = (path, data) => dispatch => {
  dispatch(embargosSlice.actions.updateJSONL({ type: actionTypes.update_jsonl, path, data }));
};

/**
 * setter - currentEmbargo currentBounding
 * @param {String} field 
 * @param {any} data 
 */
const setCurrentBounding = (field, data) => dispatch => {
  dispatch(embargosSlice.actions.setCurrentBounding({ type: actionTypes.set_current_embargo, field, data }));
};

const setCurrentBoundingFields = (fields) => dispatch => {
  dispatch(embargosSlice.actions.setCurrentBoundingFields({fields}));
};

/**
 * add a horizontal or vertical line in the currentBounding
 * @param {String} direction 
 * @param {any} data 
 */
const addlLine = (direction, data) => dispatch => {
  switch (direction) {
    case "vertical":
      dispatch(embargosSlice.actions.addVerticalLine({ data }));
      break;

    case "horizontal":
      dispatch(embargosSlice.actions.addHorizontalLine({ data }));
      break;

    default:
      break;
  }
};

const deleteLine = (direction, index) => dispatch => {
  switch (direction) {
    case "vertical":
      dispatch(embargosSlice.actions.deleteVerticalLine({ index }));
      break;

    case "horizontal":
      dispatch(embargosSlice.actions.deleteHorizontalLine({ index }));
      break;

    default:
      break;
  }
};

// API consum - get embargos list
const getEmbargosList = props => (dispatch, getState) => {
  requestFromServer.getEmbargosList(props, getState().auth.authToken)
  .then(response => {
    checkRequestErrors( dispatch, response );
    const total = response.headers.get('X-Total-Count');
    dispatch( embargosSlice.actions.setEmbargo({ type: actionTypes.set_embargo, field: "totalCount", data: total  }) )
    return response.json();
  })
  .then(response => {
    dispatch(embargosSlice.actions.setEmbargosList({ type: actionTypes.set_embargos_list, table: response }));
  })
  .catch(err => {
    dispatch(notificationActions.setNotification( errorMessage( err.message ) , "error"));
  })
};

// API consum - get embargos list
const getEmbargosInProcess = props => (dispatch, getState) => {
  requestFromServer.getEmbargosInProcess(props, getState().auth.authToken)
  .then(response => {
    checkRequestErrors( dispatch, response );
    const total = response.headers.get('X-Total-Count');
    dispatch( embargosSlice.actions.setEmbargo({ type: actionTypes.set_embargo, field: "totalCount", data: total  }) )
    return response.json();
  })
  .then(response => {
    let data = [];
    response.forEach( (item) => {
      const missing = item.total - ( item.exitosos + item.fallidos )
      item.missing = missing;
      data.push(item);
    });
    dispatch(embargosSlice.actions.setEmbargosList({ type: actionTypes.set_embargos_list, table: data }));
  })
  .catch(err => {
    dispatch(notificationActions.setNotification( errorMessage( err.message ) , "error"));
  })
};

// API consum - get files of an specific embargo 
const getEmbargoData = id => (dispatch, getState) => {
  return requestFromServer.getEmbargoData(id, getState().auth.authToken)
  .then(response => {
    checkRequestErrors( dispatch, response );
    return response.json();
  })
  .then(response => {
    dispatch(
      embargosSlice.actions.setCurrentEmbargo(
        { type: actionTypes.set_current_embargo, field: "data", data: response }
      )
    );
    
    // documents
    let requestOptions = {
      method: 'GET',
      redirect: 'follow'
    };

    let isValidFormat = true;

    fetch(response.urlEmbargoFile, requestOptions)
    .then(response => {
      let exp = new RegExp("^http(s?)\:\/\/[0-9a-zA-Z]([-.\w]*[0-9a-zA-Z])*(:(0-9)*)*(\/?)([a-zA-Z0-9\-\.\?\,\'\/\\\+&amp;%\$#_]*)?(.pdf).*", 'i');
      isValidFormat = exp.test(response.url);
      return response.blob();

    })
    .then(response => {
      let url = window.URL.createObjectURL(new Blob([response], { type: 'application/pdf' }, 'view.pdf'));
      
      dispatch(
        embargosSlice.actions.setCurrentEmbargo(
          { 
            type: actionTypes.set_current_embargo, 
            field: "document", 
            data: { file: url, type: isValidFormat ? "application/pdf" : response.type } 
          }
        )
      );
    });

    fetch(response.urlResponse, requestOptions)
    .then(res => res.json())
    .then(json => dispatch(embargosSlice.actions.setCurrentEmbargo(
      { type: actionTypes.set_current_embargo, field: "json", data: json }
    )));
    
    fetch(response.urlExtractionResponse, requestOptions)
    .then(res => res.json())
    .then(json1 => dispatch(embargosSlice.actions.setCurrentEmbargo(
      { type: actionTypes.set_current_embargo, field: "json1", data: json1 }
    )));
     
  })
  .catch(err => {
    dispatch(notificationActions.setNotification( errorMessage( err.message ) , "error"));
  })
};

const deleteEmbargo = id => (dispatch, getState) => {
  return requestFromServer.deleteEmbargo(id, getState().auth.authToken)
  .then(response => {
    checkRequestErrors( dispatch, response );
    dispatch(notificationActions.setNotification( "Se ha eliminado el embargo" , "success"));
    return true;
  })
  .catch(err => {
    dispatch(notificationActions.setNotification( errorMessage( err.message ) , "error"));
    return false;
  });
};

// API consum - get defendant (demandados) list
const getDefendantList = props => (dispatch, getState) => {
  return requestFromServer.getDemandadosList({...props, sort: "id,desc"}, getState().auth.authToken)
  .then( response => {
    checkRequestErrors( dispatch, response );
    const total = response.headers.get('X-Total-Count');
    const totalPages = Math.ceil(total / props.size);
    dispatch(
      embargosSlice.actions.setCurrentEmbargo(
        { type: actionTypes.set_current_embargo, field: "defendantsTotalCount", data: total }
      )
    );
    dispatch(
      embargosSlice.actions.setCurrentEmbargo(
        { type: actionTypes.set_current_embargo, field: "totalPages", data: totalPages }
      )
    );
    return response;
  })
  .then( responeObj => responeObj.json())
  .then( body => {
    dispatch(
      embargosSlice.actions.setCurrentEmbargo(
        { type: actionTypes.set_current_embargo, field: "defendantsList", data: body }
      )
    );
    return getState().embargos.currentEmbargo.totalPages;
  })
  .catch(err => {
    dispatch(notificationActions.setNotification( errorMessage( err.message ) , "error"));
  });
};

/**
 * API consum - get the defendant table
 */
const getDefendantTable = (idEmbargo, page) => (dispatch, getState) => {
  const { currentBounding } = getState().embargos.currentEmbargo;
  return requestFromServer.getDemandadosTable({ idEmbargo, mode: currentBounding.executionMode }, getState().auth.authToken,
    {
      horizontalLines: currentBounding.pointHorizontalLines,
      verticalLines: currentBounding.pointVerticalLines,
      keyColumns: {nombre: "0", identificacion: "1", expendiente: "2", monto: "3"},
      page: page - 1,
      hint: currentBounding.hint === "A" ? null : currentBounding.hint,
      boundingPoly: {
        vertices: currentBounding.rectangle.vertices
      }  
    }
  )
  .then(response => {
    checkRequestErrors( dispatch, response );
    return response.json();
  })
  .then(data => {
    data.tables.map(value => {
      dispatch(embargosSlice.actions.setCurrentBounding({ 
        type: actionTypes.set_current_embargo,
        field: "pointHorizontalLines",
        data: value.horizontalLines 
      }));
      dispatch(embargosSlice.actions.setCurrentBounding({ 
        type: actionTypes.set_current_embargo,
        field: "pointVerticalLines",
        data: value.verticalLines 
      }));
      dispatch(embargosSlice.actions.setCurrentEmbargo({ 
        field: "dataTable",
        data: data 
      }));
    });
    if ( getState().embargos.currentEmbargo.dataTable.tables?.length )
      dispatch(notificationActions.setNotification("Datos cargados", "success"));
    else
      dispatch(notificationActions.setNotification("No se han reconocido datos", "error"));
  })
  .catch(err => {
    dispatch(notificationActions.setNotification( errorMessage( err.message ) , "error"));
  });
};

/**
 * 
 * @param {*} idEmbargo 
 */
const saveDefendants = (idEmbargo, defendants) => (dispatch, getState) => {
  return requestFromServer.saveDemandados({ idEmbargo, defendants }, getState().auth.authToken)
  .then(response => {
    checkRequestErrors( dispatch, response );
    return true;
  })
  .catch(err => {
    dispatch(notificationActions.setNotification( errorMessage( err.message ) , "error"));
    return false;
  });
};

const deleteDefendant = id => (dispatch, getState) => {
  return requestFromServer.deleteDemandado(id, getState().auth.authToken)
  .then(response => {
    checkRequestErrors( dispatch, response );
    dispatch(notificationActions.setNotification("Demandante eliminado exitosamente", "success"));
    return true;
  })
  .catch(err => {
    dispatch(notificationActions.setNotification( errorMessage( err.message ) , "error"));
    return false;
  });
};

const getPlaintiffs = id => (dispatch, getState) => {
  return requestFromServer.getEmbargoData(id, getState().auth.authToken)
  .then(response => {
    checkRequestErrors( dispatch, response );
    return response.json();
  })
  .then(response => {
    dispatch(embargosSlice.actions.setCurrentEmbargo({ type: actionTypes.set_embargo, field: "plaintiffsList", data: response.plaintiffs }));
    dispatch(notificationActions.setNotification("Demandantes guardados exitosamente", "success"));
  })
  .catch(err => {
    dispatch(notificationActions.setNotification( errorMessage( err.message ) , "error"));
    return false;
  });
};

const savePlaintiffs = (idEmbargo, plaintiffs) => async (dispatch, getState) => {
  return requestFromServer.saveDemandantes({ idEmbargo, plaintiffs }, getState().auth.authToken)
  .then(response => {
    checkRequestErrors( dispatch, response );
    dispatch(notificationActions.setNotification("Demandantes guardados exitosamente", "success"));
    return true;
  })
  .catch(err => {
    dispatch(notificationActions.setNotification( errorMessage( err.message ) , "error"));
    return false;
  });
};

const deletePlaintiffs = id => async (dispatch, getState) => {
  return requestFromServer.deleteDemandante(id, getState().auth.authToken)
  .then(response => {
    checkRequestErrors( dispatch, response );
    dispatch(notificationActions.setNotification("Demandante eliminado exitosamente", "success"));
    return true;
  })
  .catch(err => {
    dispatch(notificationActions.setNotification( errorMessage( err.message ) , "error"));
    return false;
  });
};

const UploadEmbargos = files => (dispatch, getState) => {
  dispatch(embargosSlice.actions.setEmbargo({ type: actionTypes.set_embargo, field: "uploading", data: true }));
  return requestFromServer.UploadEmbargos(files, getState().auth.authToken)
  .then(response => {
    checkRequestErrors( dispatch, response );
    dispatch(embargosSlice.actions.setEmbargo({ type: actionTypes.set_embargo, field: "uploading", data: false }));
    dispatch(notificationActions.setNotification("Documentos subidos exitosamente", "success"));
    return true;
  })
  .catch(err => {
    dispatch(notificationActions.setNotification( errorMessage( err.message ) , "error"));
    dispatch(embargosSlice.actions.setEmbargo({ type: actionTypes.set_embargo, field: "uploading", data: false }));
    return false;
  });
};

const confirmEmbargo = embargo_data => (dispatch, getState) => {
  return requestFromServer.confirmEmbargo(embargo_data, getState().auth.authToken)
  .then(response => {
    checkRequestErrors( dispatch, response );
    return requestFromServer.executeEmbargo( embargo_data.id, getState().auth.authToken);
  })
  .then(response => {
    checkRequestErrors( dispatch, response );
    dispatch(notificationActions.setNotification("Embargo confirmado satisfactoriamente", "success"));
    return true;
  })
  .catch(err => {
    dispatch(notificationActions.setNotification( errorMessage( err.message ) , "error"));
    return false;
  });
};

const reconfirmEmbargo = () => (dispatch, getState) => {
  return requestFromServer.executeEmbargo(
    getState().embargos.currentEmbargo.data.id, 
    getState().auth.authToken
  );
};

const cancelEmbargoProcess = props => (dispatch, getState) => {
  return requestFromServer.cancelEmbargoInProcess(props, getState().auth.authToken)
  .then(response => {
    checkInProcessErrors( response );
    dispatch(notificationActions.setNotification("Proceso cancelado", "success"));
    return true;
  })
  .catch(err => {
    dispatch(notificationActions.setNotification( errorMessage( err.message ) , "error"));
    return false;
  });
};

const updateDataset = (idEmbargo) => async (dispatch, getState) => {
  return requestFromServer.updateDataset(
    {...getState().embargos.currentEmbargo.json1, idEmbargo}, 
    getState().auth.authToken
  )
    .then(response => {
      checkRequestErrors( dispatch, response );
      return true;
    })
    .catch(err => {
      dispatch(notificationActions.setNotification( "Error dataset: " + errorMessage( err.message ) , "error"));
      return false;
    });
};

const ReplaceJSONLValues = (tableName, listName, fields) => async (dispatch, getState) => {
  const currentEmbargo = getState().embargos.currentEmbargo;
  const jsonl_values = currentEmbargo.json1.fields[tableName]?.fieldInstances;
  if ( jsonl_values ){
    let keys = Object.keys(jsonl_values).filter(value => value < 0);
    currentEmbargo[listName].forEach(element => {
      if(!jsonl_values[element.id]) {
        keys.forEach(key => {
          let correct = false;
          fields.forEach(field => {
            if(element[field[0]] && jsonl_values[key].parts[field[1]]) {
              correct = correct ||  element[field[0]] ===  jsonl_values[key].parts[field[1]].content.replace(/[^\w\s]/gi, '');
            }
          });
          if(correct) {
            dispatch(embargosSlice.actions.setJSONL({
              type: actionTypes.update_jsonl, field: tableName, id: element.id, key, value: jsonl_values[key]
            }));
          }
        })
      }
    });
  }
};

const udpateAllDefendantsID = ( idType, idEmbargo , reload ) => async (dispatch, getState) => {
  const request = await requestFromServer.getDemandadosList(
    { idEmbargo, size: getState().embargos.currentEmbargo.defendantsTotalCount }, getState().auth.authToken);
  
  let defendantList = await request.json();
  defendantList.forEach( defendant => defendant.typeIdentification = idType );
  await dispatch( saveDefendants( idEmbargo, defendantList ) );
  reload(null);
};

const deleteAllDefendants = ( idEmbargo , reload ) => async (dispatch, getState) => {
  try {
    const response = await requestFromServer.deleteAllDefendants( idEmbargo, getState().auth.authToken);
    checkRequestErrors( dispatch, response );
    reload(null); 
  } catch (err) {
    dispatch(notificationActions.setNotification( "Error: " + errorMessage( err.message ) , "error"));
  }
};

const getUniqueColumnID = ( columnIndex ) => async (dispatch, getState) => {
  try {
    const embargoID = getState().embargos.currentEmbargo.data.id;
    const response = await requestFromServer.getSpreadsheetKeys( columnIndex, embargoID, getState().auth.authToken);
    checkRequestErrors( dispatch, response );
    const json = await response.json();
    return json;
  } catch (err) {
    dispatch(notificationActions.setNotification( "Error: " + errorMessage( err.message ) , "error"));
  }
};

const repeatedProcess = ( ) => async (dispatch, getState) => {
  try {
    const embargoID = getState().embargos.currentEmbargo.data.id;
    const response = await requestFromServer.getRepeated( embargoID, getState().auth.authToken);
    checkRequestErrors( dispatch, response );
    const repeatedList = await response.json();
    if ( repeatedList.length > 0 ){
      const responseRep = await requestFromServer.postFuseRepeated( embargoID, getState().auth.authToken);
      checkRequestErrors( dispatch, responseRep );
      return true;
    }else{
      return true;
    }
  } catch (err) {
    dispatch(notificationActions.setNotification( "Error: " + errorMessage( err.message ) , "error"));
    return false;
  }
};

const getEmailNotificationList = props => (dispatch, getState) => {
  requestFromServer.getEmailNotificationList(props, getState().auth.authToken)
  .then(response => {
    checkRequestErrors( dispatch, response );
    const total = response.headers.get('X-Total-Count');
    dispatch( embargosSlice.actions.setEmbargo({ type: actionTypes.set_embargo, field: "totalCount", data: total  }) )
    return response.json();
  })
  .then(response => {
    dispatch(embargosSlice.actions.setEmbargosList({ type: actionTypes.set_embargos_list, table: response }));
  })
  .catch(err => {
    dispatch(notificationActions.setNotification( errorMessage( err.message ) , "error"));
  })
};

const setEmailNotification = (id, email) => (dispatch, getState) => {
  return requestFromServer.notifyEmbargo(id, email, getState().auth.authToken)
  .then(response => {
    checkRequestErrors( dispatch, response );
    dispatch(notificationActions.setNotification("Embargo notificado", "success"));
    return true;
  })
  .catch(err => {
    dispatch(notificationActions.setNotification( errorMessage( err.message ) , "error"));
    return false;
  });
}

const getNotificationUpdateStatus = () => (dispatch, getState) => {
  return requestFromServer.getNotificationUpdateStatus(getState().auth.authToken)
  .then(response => {
    checkRequestErrors( dispatch, response );
    dispatch(notificationActions.setNotification("Notificaciones actualizadas", "success"));
    return true;
  })
  .catch(err => {
    dispatch(notificationActions.setNotification( errorMessage( err.message ) , "error"));
    return false;
  });
}


export const actions = {
  setEmbargosList,
  setEmbargosService,
  setCurrentEmbargo,
  repeatedProcess,
  getEmbargosList,
  getEmbargosInProcess,
  getEmbargoData,
  deleteEmbargo,
  getDefendantList,
  getDefendantTable,
  getUniqueColumnID,
  setCurrentBounding,
  setCurrentBoundingFields,
  getEmailNotificationList,
  setEmailNotification,
  getNotificationUpdateStatus,
  addlLine,
  deleteLine,
  deleteAllDefendants,
  saveDefendants,
  setEmbargo,
  UploadEmbargos,
  confirmEmbargo,
  cleanCurrentEmbargo,
  deleteDefendant,
  savePlaintiffs,
  deletePlaintiffs,
  getPlaintiffs,
  reconfirmEmbargo,
  cancelEmbargoProcess,
  updateJSONL,
  ReplaceJSONLValues,
  updateDataset,
  udpateAllDefendantsID,
};

export const embargosSlice = createSlice({
  name: "embargos",
  initialState: inititalEmbargosState,
  reducers: {
    setEmbargosList: (state, action) => {
      const { table } = action.payload;
      state.embargosList = table;
    },
    setService: (state, action) => {
      const { embargoService } = action.payload;
      state.embargosService = embargoService;
    },
    setEmbargo: (state, action) => {
      const { field, data } = action.payload;
      state[field] = data;
    },
    setCurrentEmbargo: (state, action) => {
      const { field, data } = action.payload;
      state.currentEmbargo[field] = data;
    },
    cleanCurrentEmbargo: (state, action) => {
      state.currentEmbargo = {
        ...state.currentEmbargo,
        document: { file: null, type: undefined },
        currentBounding: {
          state: boundingState.init,
          points: [],
          rectangle: {x: 0, y: 0, width: 0, height: 0, vertices: []},
          pointVerticalLines: [],
          pointHorizontalLines: [],
          executionMode: ExecutionModeTypes.auto,
          hint: "LINES"
        },
        currentPage: 1,
        numPages: 1,
        defendantsTotalCount: 0,
        boundingWords: "",
        onFocus: {
          field: undefined,
          id: undefined,
          part: undefined,
          index: undefined,
          formField: undefined
        },
        dataTable: {}
      }
    },
    setCurrentBounding: (state, action) => {
      const { field, data } = action.payload;
      state.currentEmbargo.currentBounding[field] = data;
    },
    setCurrentBoundingFields: (state, action) => {
      const { fields } = action.payload;
      fields.map( field  => state.currentEmbargo.currentBounding[field.field] = field.data)
      
    },
    addVerticalLine: (state, action) => {
      const { data } = action.payload;
      state.currentEmbargo.currentBounding.pointVerticalLines.push(data);
    },
    addHorizontalLine: (state, action) => {
      const { data } = action.payload;
      state.currentEmbargo.currentBounding.pointHorizontalLines.push(data);
    },
    deleteVerticalLine: (state, action) => {
      const { index } = action.payload;
      state.currentEmbargo.currentBounding.pointVerticalLines.splice(index, 1);
    },
    deleteHorizontalLine: (state, action) => {
      const { index } = action.payload;
      state.currentEmbargo.currentBounding.pointHorizontalLines.splice(index, 1);
    },
    updateJSONL: (state, action) => {
      const { path, data } = action.payload;
      let ans = {};
      try {
        ans = {
          ...state.currentEmbargo.json1.fields,
          //[path_list[0]]: {
          [path.field]: {
            fieldInstances: {
              //...state.currentEmbargo.json1.fields[path_list[0]].fieldInstances,
              ...state.currentEmbargo.json1.fields[path.field].fieldInstances,
              [path.id]: {
                parts: {
                  // ...state.currentEmbargo.json1.fields[path_list[0]].fieldInstances[path_list[1]]
                  //   ? state.currentEmbargo.json1.fields[path_list[0]].fieldInstances[path_list[1]].parts 
                  //   : {},
                  // [path_list[2]]: data
                  ...state.currentEmbargo.json1.fields[path.field].fieldInstances[path.id]
                    ? state.currentEmbargo.json1.fields[path.field].fieldInstances[path.id].parts 
                    : {},
                  [path.part]: data
                  
                }
              }
            }
          }
        } 
        state.currentEmbargo.json1.fields = ans;
      } catch(error) {
      }
    },
    setJSONL: (state, action) => {
      const { field, id, key, value } = action.payload;
      state.currentEmbargo.json1.fields[field].fieldInstances[id] = value;
      delete state.currentEmbargo.json1.fields[field].fieldInstances[key];
    }
  }
});
