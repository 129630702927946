import React from "react";
import { Redirect, Switch } from "react-router-dom";
import { ContentRoute } from "../../../_metronic/layout";
import { Interval } from "../../pages/System/Interval";
import IdentityTypes from "./../../pages/System/IdentityTypes";

function SystemPage() {
  return (
    <Switch>
        <Redirect exact={true} from="/config" to="/config/interval" />
        <ContentRoute path="/config/interval" component={ Interval } />
        <ContentRoute path="/config/identity-types" component={ IdentityTypes } />
        <Redirect to="error/error-v1" />
    </Switch>
  );
}

export default SystemPage;
